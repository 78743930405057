<template>
  <div>
    <!-- Search and Toggle Bar -->
    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 16px;">
      <!-- Search bar -->
      <a-input
        :placeholder="$t('command.search')"
        v-model="searchTerm"
        @input="onSearch"
        style="width: 300px;"
      />

      <div style="display: flex; align-items: center;">
        <a-switch
          :checked="!showArchived"
          @change="toggleArchived"
          :checked-children="$t('utilisateur.active')"
          :un-checked-children="$t('utilisateur.nonActive')" 
        />
      </div>
      
    </div>

    <a-table
      :loading="commandTableLoading"
      :rowKey="'id_command'"
      :columns="commandColumns"
      :data-source="filteredCommands"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <!-- Columns -->
      <template v-for="col in ['reference', 'date', 'createdAt', 'updatedAt', 'status', 'state', 'createdBy']" :slot="col" slot-scope="text, record">
        
        <!-- Status color based on availability -->
        <div v-if="col === 'reference'" :key="'reference' + record.reference">
          <a-tag :color="record.status === 'available' ? 'green' : 'red'">
            {{ record.etat === 'available' ? $t("command.available") : $t("command.unavailable") }}
          </a-tag>
        </div>
        
        <!-- Date formatting -->
        <div v-else-if="col === 'date'" :key="'date-' + record.createdAt">
          {{ moment(text).format("DD/MM/YYYY HH:mm") }}
        </div>

        <div v-else-if="col === 'createdAt'" :key="'createdAt-' + record._id">
          {{ moment(record.createdAt).format("DD/MM/YYYY HH:mm") }}
        </div>

        <div v-else-if="col === 'updatedAt'" :key="'updatedAt-' + record._id">
          {{ moment(record.updatedAt).format("DD/MM/YYYY HH:mm") }}
        </div>

        <!-- State translated and color-tagged -->
        <div v-else-if="col === 'state'" :key="'state-' + record._id">
          <a-tag :color="stateTagColor(record.state)">
            {{ displayState(record.state) }}
          </a-tag>
        </div>

        <!-- Created by user information -->
        <div v-else-if="col === 'createdBy'" :key="'createdBy-' + record._id">
          {{ record.createdBy.userName }}
        </div>
      </template>

      <span slot="operation" slot-scope="text, record">
        <!-- Always show the "View" button -->
        <span class="button-spacing">
          <a-button @click="() => $router.push('/command/' + record._id)">
            <a-icon type="eye" /> {{ $t("action.voir") }}
          </a-button>
        </span>
        
        <!-- Show "Edit" and "Cancel" buttons only if the command is active -->
        <span v-if="record.status === 'active'" class="button-spacing">
          <a-button type="primary" @click="showEditModal(record)">
            <a-icon type="edit" />{{ $t("action.modifier") }}
          </a-button>
        </span>
        
        <span v-if="record.status === 'active'" class="button-spacing">
          <a-popconfirm :title="$t('requis.supp')" @confirm="deleteCommand(record._id)">
            <a-button type="danger">
              <a-icon type="delete" />  
              <span style="margin-left: 8px;">{{ $t("action.annuler") }}</span>
            </a-button>
          </a-popconfirm>
        </span>
      </span>
      
    </a-table>

    <!-- Edit Modal -->
    <a-modal
      v-model="isEditModalVisible"
      :title="$t('action.modifier')"
      @ok="updateCommand"
      @cancel="closeEditModal"
    >
      <div>
        <label for="state">{{ $t('command.state') }}:</label>
        <a-select v-model="selectedState" style="width: 100%" placeholder="Select state">
          <a-select-option value="pending">{{ $t('command.pending') }}</a-select-option>
          <a-select-option value="in progress">{{ $t('command.inProgress') }}</a-select-option>
          <a-select-option value="validated">{{ $t('command.validated') }}</a-select-option>
        </a-select>
      </div>
      <div style="margin-top: 16px;">
        <label for="note">{{ $t('command.note') }}:</label>
        <a-textarea
          v-model="note"
          rows="4"
          placeholder="Enter note for the command"
        />
      </div>
    </a-modal>
  </div>
</template>



<script>
import apiClient from '@/services/axios';
import moment from "moment";

export default {
  name: "CommandList",
  data() {
    return {
      commandTableLoading: false,
      commands: [],
      filteredCommands: [],
      searchTerm: "",
      isEditModalVisible: false,
      selectedCommand: null,
      selectedState: "",
      note: "",
      showArchived: false,
    };
  },
  computed: {
  commandColumns() {
    return [
      { 
        title: this.$t("product.reference"), 
        dataIndex: "reference", 
        key: "reference",
        sorter: (a, b) => a.reference.localeCompare(b.reference)
      },
      {
  title: this.$t("command.clientName"), 
  dataIndex: "createdBy", 
  key: "createdBy", 
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style="padding: 8px;">
      <a-input
        placeholder="Search client"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style="width: 188px; margin-bottom: 8px; display: block;"
      />
      <a-button
        type="primary"
        icon="search"
        onClick={() => confirm()}
        size="small"
        style="width: 90px; margin-right: 8px;"
      >
      { this.$t('paiement.chercher') }
      </a-button>
      <a-button
        onClick={() => clearFilters()}
        size="small"
        style="width: 90px;"
      >
      { this.$t('action.reinitialiser') }
      </a-button>
    </div>
  ),
  onFilter: (value, record) => {
    const mother = record.createdBy?.mother?.firstName + ' ' + record.createdBy?.mother?.lastName || '';
    const father = record.createdBy?.father?.firstName + ' ' + record.createdBy?.father?.lastName || '';
    return mother.toLowerCase().includes(value.toLowerCase()) || father.toLowerCase().includes(value.toLowerCase());
  },
  customRender: (text, record) => {
    const mother = record.createdBy?.mother;
    const father = record.createdBy?.father;
    return (
      <div>
        {mother && <div>{mother.firstName} {mother.lastName}</div>}
        {father && <div>{father.firstName} {father.lastName}</div>}
      </div>
    );
  }
},
{
  title: this.$t("profil.tel"), 
  dataIndex: "createdBy", 
  key: "phone", 
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style="padding: 8px;">
      <a-input
        placeholder="Search phone"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style="width: 188px; margin-bottom: 8px; display: block;"
      />
      <a-button
        type="primary"
        icon="search"
        onClick={() => confirm()}
        size="small"
        style="width: 90px; margin-right: 8px;"
      >
      { this.$t('paiement.chercher') }
      </a-button>
      <a-button
        onClick={() => clearFilters()}
        size="small"
        style="width: 90px;"
      >
      { this.$t('action.reinitialiser') }
      </a-button>
    </div>
  ),
  onFilter: (value, record) => {
    const motherPhone = record.createdBy?.mother?.phone || '';
    const fatherPhone = record.createdBy?.father?.phone || '';
    return motherPhone.includes(value) || fatherPhone.includes(value);
  },
  customRender: (text, record) => {
    const mother = record.createdBy?.mother;
    const father = record.createdBy?.father;
    return (
      <div>
        {mother && mother.phone && <div>{mother.phone}</div>}
        {father && father.phone && <div>{father.phone}</div>}
      </div>
    );
  }
}
,
{
  title: this.$t("command.productName"), 
  dataIndex: "marketProduct", 
  key: "marketProduct",
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style="padding: 8px;">
      <a-input
        placeholder="Search product"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style="width: 188px; margin-bottom: 8px; display: block;"
      />
      <a-button
        type="primary"
        icon="search"
        onClick={() => confirm()}
        size="small"
        style="width: 90px; margin-right: 8px;"
      >
      { this.$t('paiement.chercher') }
      </a-button>
      <a-button
        onClick={() => clearFilters()}
        size="small"
        style="width: 90px;"
      >
      { this.$t('action.reinitialiser') }
      </a-button>
    </div>
  ),
  onFilter: (value, record) => {
    return record.marketProduct.some(product => product.name.toLowerCase().includes(value.toLowerCase()));
  },
  customRender: (text, record) => (
    <div>
      {record.marketProduct.map(product => (
        <a-tag key={product._id} style="display: block; margin-bottom: 8px;">
          {product.name}
        </a-tag>
      ))}
    </div>
  )
},
      { 
          title: this.$t("command.state"), 
          dataIndex: "state", 
          key: "state", 
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style="padding: 8px;">
              <a-checkbox-group
                value={selectedKeys}
                onChange={(checkedValues) => setSelectedKeys(checkedValues)}
                style="display: block;"
              >
                <a-checkbox value="pending" style="display: block;">{this.$t('command.pending')}</a-checkbox>
                <a-checkbox value="in progress" style="display: block;">{this.$t('command.inProgress')}</a-checkbox>
                <a-checkbox value="validated" style="display: block;">{this.$t('command.validated')}</a-checkbox>
                <a-checkbox value="cancelled" style="display: block;">{this.$t('command.cancelled')}</a-checkbox>
              </a-checkbox-group>
              <a-button
                type="primary"
                icon="search"
                onClick={() => confirm()}
                size="small"
                style="width: 90px; margin-top: 8px; margin-right: 8px;"
              >
              { this.$t('paiement.chercher') }
              </a-button>
              <a-button
                onClick={() => clearFilters()}
                size="small"
                style="width: 90px; margin-top: 8px;"
              >
              { this.$t('action.reinitialiser') }
              </a-button>
            </div>
          ),
          onFilter: (value, record) => value.includes(record.state),
          customRender: (text, record) => (
            <a-tag color={this.stateTagColor(record.state)}>
              {this.displayState(record.state)}
            </a-tag>
          )
        },
      { 
        title: this.$t("command.createdAt"), 
        dataIndex: "createdAt", 
        key: "createdAt", 
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(), 
        customRender: (text) => {
          return text ? moment(text).format("DD/MM/YYYY HH:mm") : this.$t("product.noUpdate");
        }
      },
      {
        title: this.$t("product.lastUpdate"),
        dataIndex: "updatedAt",
        key: "updatedAt",
        sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(), 
        customRender: (text) => {
          return text ? moment(text).format("DD/MM/YYYY HH:mm") : this.$t("product.noUpdate");
        },
      },
      { 
        title: this.$t("action.actions"), 
        dataIndex: "operation", 
        scopedSlots: { customRender: "operation" } ,
        fixed: "right",
      },
    ];
  }
},
  methods: {
    fetchCommands() {
      this.commandTableLoading = true;
      apiClient.post("/marketplace/command", { query: {} }).then(response => {
        this.commands = response.data;
        this.filterCommands();
        this.commandTableLoading = false;
      }).catch(() => {
        this.commandTableLoading = false;
        this.$message.error(this.$t("error.fetchCommands"));
      });
    },
    filterCommands() {
      if (this.showArchived) {
        this.filteredCommands = this.commands.filter(command => command.status === 'inactive');
      } else {
        this.filteredCommands = this.commands.filter(command => command.status === 'active');
      }
      
      if (this.searchTerm) this.onSearch();
    },
    toggleArchived() {
      this.showArchived = !this.showArchived;
      this.filterCommands();
    },
    deleteCommand(commandId) {
      this.commandTableLoading = true;
      apiClient.delete("/marketplace/command/" + commandId).then(() => {
        this.$message.success(this.$t("success.commandSupp"));
        this.fetchCommands();
      }).catch(() => {
        this.$message.error(this.$t("error.autorisationSupp"));
      }).finally(() => {
        this.commandTableLoading = false;
      });
    },
    onSearch() {
    const searchLower = this.searchTerm.toLowerCase().trim();
    
    const searchParts = searchLower.split(/\s+/);

    this.filteredCommands = this.commands.filter(command => {
      const reference = command.reference?.toLowerCase() || '';
      const motherFirstName = command.createdBy?.mother?.firstName?.toLowerCase() || '';
      const motherLastName = command.createdBy?.mother?.lastName?.toLowerCase() || '';
      const fatherFirstName = command.createdBy?.father?.firstName?.toLowerCase() || '';
      const fatherLastName = command.createdBy?.father?.lastName?.toLowerCase() || '';

      const matchesReference = reference.includes(searchLower);
      const matchesMother = searchParts.every(part =>
        motherFirstName.includes(part) || motherLastName.includes(part)
      );
      const matchesFather = searchParts.every(part =>
        fatherFirstName.includes(part) || fatherLastName.includes(part)
      );

      return (matchesReference || matchesMother || matchesFather) &&
        (this.showArchived ? command.status === 'inactive' : command.status === 'active');
    });
  },
    showEditModal(record) {
      this.selectedCommand = record;
      this.selectedState = record.state;
      this.note = "";
      this.isEditModalVisible = true;
    },
    closeEditModal() {
      this.isEditModalVisible = false;
    },
    updateCommand() {
      const payload = {};
      if (this.selectedState !== this.selectedCommand.state) payload.state = this.selectedState;
      if (this.note.trim()) payload.note = this.note;
      if (!Object.keys(payload).length) {
        this.$message.info(this.$t("no.changes"));
        return;
      }
      apiClient.put(`/marketplace/command/${this.selectedCommand._id}`, payload).then(() => {
        this.$message.success(this.$t("success.commandUpdate"));
        this.isEditModalVisible = false;
        this.fetchCommands();
      }).catch(() => {
        this.$message.error(this.$t("error.commandUpdate"));
      });
    },
    displayState(state) {
      switch (state) {
        case 'pending': return this.$t('command.pending');
        case 'in progress': return this.$t('command.inProgress');
        case 'validated': return this.$t('command.validated');
        case 'cancelled': return this.$t('command.cancelled');
        default: return state;
      }
    },
    stateTagColor(state) {
      switch (state) {
        case 'pending': return 'orange';
        case 'in progress': return 'blue';
        case 'validated': return 'green';
        default: return 'gray';
      }
    },
  },
  created() {
    this.fetchCommands();
  },
};
</script>

<style scoped>
.modal-content {
  margin-bottom: 16px;
}
.button-spacing {
  margin-right: 30px;
}


a-tag {
  display: block;
}
</style>
