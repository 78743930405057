<template>
  <a-modal
    :visible="visible"
    @cancel="handleCancel"
    :title="$t('product.addProduct')"
    :width="1000"
    :footer="null"
  >
    <a-form :form="form" @submit="handleSubmit">
      <!-- Row 1: Product Name and Price -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.name')">
            <a-input
              v-decorator="['name', { rules: [{ required: true, message: $t('requis.nom') }] }]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.price')">
            <a-input-number
              v-decorator="['price', { rules: [{ required: true, message: $t('product.reqprice') }] }]"
              :min="0"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 2: Availability and Reference -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.availability')">
            <a-select
              v-decorator="['availability', { rules: [{ required: true, message: $t('product.reqavailability') }] }]"
            >
              <a-select-option value="available">{{ $t('product.available') }}</a-select-option>
              <a-select-option value="soon">{{ $t('product.comingSoon') }}</a-select-option>
              <a-select-option value="out">{{ $t('product.outOfStock') }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.reference')">
            <a-input v-decorator="['reference', { rules: [{ required: false }] }]" :placeholder="$t('product.ref')" />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 3: Quantity and Tags -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.quantity')">
            <a-input-number
              v-decorator="['quantity', { rules: [{ required: true, message: $t('product.reqquantity') }] }]"
              :min="0"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.tags')">
            <a-select mode="tags" :placeholder="$t('product.tag')" v-decorator="['tags', { rules: [{ required: true, message: $t('requis.reqtags') }] }]">
              <a-select-option v-for="tag in existingTags" :key="tag" :value="tag">
                {{ tag }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 4: Description -->
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item :label="$t('product.description')" class="quill-editor-container">
            <quill-editor v-model="description" :options="editorOptions" />
            <div v-if="descriptionError" class="error-message">{{ descriptionError }}</div>
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 5: Cover Picture and Additional Pictures -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.coverPicture')">
            <a-upload-dragger
  v-model="fileList"
  :before-upload="(file) => beforeUploadContent(file, 'cover')"
  :multiple="false"
  accept=".jpg,.jpeg,.png"
>
  <p class="ant-upload-drag-icon">
    <a-icon type="inbox" />
  </p>
  <p class="ant-upload-text">{{ $t('product.uploadCoverPicture') }}</p>
  <p class="ant-upload-hint">{{ $t('actualite.cliquer') }}</p>
</a-upload-dragger>

          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.pictures')">
            <a-upload-dragger
  v-model="picturesFileList"
  :before-upload="(file) => beforeUploadContent(file, 'pictures')"
  :multiple="true"
  accept=".jpg,.jpeg,.png"
>
  <p class="ant-upload-drag-icon">
    <a-icon type="inbox" />
  </p>
  <p class="ant-upload-text">{{ $t('product.uploadAdditionalPictures') }}</p>
  <p class="ant-upload-hint">{{ $t('actualite.cliquer') }}</p>
</a-upload-dragger>

          </a-form-item>
        </a-col>
      </a-row>

      <!-- Submit and Cancel Buttons -->
      <a-form-item>
        <a-button type="primary" :loading="loading" htmlType="submit" style="margin-right: 10px">
          {{ $t('product.submit') }}
        </a-button>
        <a-button type="default" @click="handleCancel">{{ $t('action.annuler') }}</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>




<script>
import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import apiClient from '@/services/axios';

export default {
  name: 'AddProduct',
  components: {
    quillEditor,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    existingTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visibleModal: false,
      form: this.$form.createForm(this),
      description: '',
      descriptionError:'',
      fileList: [], 
      picturesFileList: [], 
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        },
      },
      loading: false, 
    };
  },
  methods: {
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
      this.fileList = [];
      this.additionalFileList = [];
      this.description = '';
      this.descriptionError = ''; 
      this.$emit('close');
    },
    handleSubmit() {
  this.loading = true; 
  this.form.validateFields(async (err, values) => {
    if (!err) {
      // Reset previous errors
      this.descriptionError = '';

      // Manual validation for description
      if (!this.description) {
  this.descriptionError = this.$t('requis.reqdescription'); 
  this.loading = false;
  return;
}


      // Manual validation for cover picture
      if (this.fileList.length === 0) {
        this.$message.error(this.$t('requis.reqcoverpicture'));
        this.loading = false;
        return;
      }

      // Proceed with form submission
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('price', values.price);
      formData.append('availability', values.availability);
      formData.append('quantity', values.quantity);
      formData.append('description', this.description);
      formData.append('status', 'active');

      if (values.reference) {
        formData.append('reference', values.reference);
      }

      if (this.fileList.length > 0) {
        formData.append('coverPicture', this.fileList[0]);
      }

      this.picturesFileList.forEach((file) => {
        formData.append('pictures', file);
      });

      if (Array.isArray(values.tags)) {
        values.tags.forEach((tag) => formData.append('tags[]', tag));
      }

      try {
        const res = await apiClient.put('/marketplace/product/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.$message.success(this.$t('product.productAdded'));
        this.handleCancel();
        this.$emit('submit', res.data);
      } catch (e) {
        console.error(e.response || e.message);
        this.$message.error(this.$t('product.impoPubAjout'));
      } finally {
        this.loading = false;
        this.form.resetFields();
      }
    } else {
      this.loading = false;
    }
  });
},
beforeUploadContent(file, type) {
  const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
  if (!isValidType) {
    this.$message.error(this.$t('product.invalidImageType')); 
    return false;
  }

  file.uid = Date.now() + '-' + Math.random().toString(36).substr(2, 9);

  if (type === 'cover') {
    this.fileList = [file];
  } else if (type === 'pictures') {
    this.picturesFileList = [...this.picturesFileList, file];
  }
  return false; 
},
  }
};

</script>
<style scoped>
  .error-message {
    color: red;
    font-size: 12px; 
    margin-top: 5px; 
  }
</style>