<template>
  <a-modal
    :visible="visible"
    @cancel="handleCancel"
    :title="$t('action.modifier')"
    :width="1000"
    :footer="null"
    @ok="handleSubmit"
  >
    <a-form :form="form" @submit="handleSubmit">
      <!-- Row 1: Product Name and Price -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.name')">
            <a-input
              v-decorator="[ 'name', { initialValue: productData.name, rules: [{ required: true, message: $t('requis.nom') }] } ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.price')">
            <a-input-number
              v-decorator="[ 'price', { initialValue: productData.price, rules: [{ required: true, message: $t('product.reqprice') }] } ]"
              :min="0"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 2: Availability and Reference -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.availability')">
            <a-select
              v-decorator="[ 'availability', { initialValue: productData.availability, rules: [{ required: true, message: $t('product.reqavailability') }] } ]"
            >
              <a-select-option value="available">{{ $t('product.available') }}</a-select-option>
              <a-select-option value="soon">{{ $t('product.comingSoon') }}</a-select-option>
              <a-select-option value="out">{{ $t('product.outOfStock') }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.reference')">
            <a-input
              v-decorator="[ 'reference', { initialValue: productData.reference, rules: [{ required: false }] } ]"
              :placeholder="$t('product.ref')"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 3: Quantity and Tags -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.quantity')">
            <a-input-number
              v-decorator="[ 'quantity', { initialValue: productData.quantity, rules: [{ required: true, message: $t('product.reqquantity') }] } ]"
              :min="0"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.tags')">
            <a-select mode="tags" :placeholder="$t('product.tag')" v-decorator="['tags', { initialValue: tagNames }]">
              <a-select-option v-for="tag in existingTags" :key="tag" :value="tag">{{ tag }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 4: Description (Full width) -->
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item :label="$t('product.description')" class="quill-editor-container">
            <quill-editor ref="quillEditor" v-model="description" :options="editorOptions" @input="onEditorInput" />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Row 5: Cover Picture and Additional Pictures -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :label="$t('product.coverPicture')">
            <a-upload-dragger
            :file-list="fileList"
            :before-upload="(file) => beforeUploadContent(file, 'cover')"
            :custom-request="handleCustomRequest"
            :multiple="false"
            accept=".jpg,.jpeg,.png"
            @remove="handleRemoveCover"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">{{ $t('product.uploadCoverPicture') }}</p>
            <p class="ant-upload-hint">{{ $t('actualite.cliquer') }}</p>
          </a-upload-dragger>
          
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="$t('product.pictures')">
            <a-upload-dragger
            :file-list="pictureFileList"
            :before-upload="(file) => beforeUploadContent(file, 'pictures')"
            :custom-request="handleCustomRequest"
            :multiple="true"
            accept=".jpg,.jpeg,.png"
            list-type="picture-card"
            @remove="handleRemovePicture"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">{{ $t('product.uploadAdditionalPictures') }}</p>
            <p class="ant-upload-hint">{{ $t('actualite.cliquer') }}</p>
          </a-upload-dragger>
          
          </a-form-item>
        </a-col>
      </a-row>

      <!-- Submit and Cancel Buttons -->
      <a-form-item>
        <a-button type="primary" :loading="loading" htmlType="submit" style="margin-right: 10px">
          {{ $t('action.modifier') }}
        </a-button>
        <a-button type="default" @click="handleCancel">{{ $t('action.annuler') }}</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import apiClient from '@/services/axios';

export default {
  name: 'EditProduct',
  components: {
    quillEditor,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    productData: {
    type: Object,
    default: () => ({
      name: '',
      coverPicture: 'default-image.png' 
    })
  },
    existingTags: {
      type: Array,
      default: () => [],
    },
    
  },
  data() {
    return {
      form: null,
      description: this.productData?.description || '',
      fileList: [], 
      pictureFileList: [], 
      removedCover: false, 
      removedPictures: [], 
      tagNames: [], 
      loading: false,
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        },
      },
    };
  },
  watch: {
    productData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            if (this.$refs.quillEditor) {
              const editor = this.$refs.quillEditor.quill;
              if (newValue.description) {
                editor.setContents(editor.clipboard.convert(newValue.description));
              } else {
                editor.setText('');
              }
            }
          });

          this.tagNames = newValue.tags ? newValue.tags.map(tag => tag.name) : [];

          if (newValue.coverPicture) {
            this.fileList = [{
              uid: '-1',
              name: 'coverPicture',
              status: 'done',
              url: newValue.coverPicture,
              thumbUrl: newValue.coverPicture,
            }];
          } else {
            this.fileList = [];
          }

          if (newValue.pictures && Array.isArray(newValue.pictures)) {
            this.pictureFileList = newValue.pictures.map((pictureUrl, index) => ({
              uid: `${index}`,
              name: `picture-${index}`,
              status: 'done',
              url: pictureUrl,
              thumbUrl: pictureUrl,
            }));
          } else {
            this.pictureFileList = [];
          }
        }
      },
    },
  },
  mounted() {
    this.form = this.$form.createForm(this);

    if (this.productData.coverPicture) {
      this.fileList = [{
        uid: '-1',
        name: 'coverPicture',
        status: 'done',
        url: this.productData.coverPicture,
        thumbUrl: this.productData.coverPicture,
      }];
    }

    if (this.productData.pictures && Array.isArray(this.productData.pictures)) {
      this.pictureFileList = this.productData.pictures.map((picture, index) => ({
        uid: `${index}`,
        name: `picture-${index}`,
        status: 'done',
        url: picture,
        thumbUrl: picture,
      }));
    }
  },
  methods: {
    handleCancel() {
      this.form.resetFields();
      this.$emit('close');
    },
    async handleSubmit() {
      this.loading = true;
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const formData = new FormData();
          formData.append('name', values.name);
          formData.append('price', values.price);
          formData.append('availability', values.availability);
          formData.append('quantity', values.quantity);

       
          formData.append('description', this.description);
          formData.append('status', 'active');

          if (values.reference) {
            formData.append('reference', values.reference);
          }

          if (this.removedCover) {
            formData.append('removeCover', true); 
          } else if (this.fileList.length > 0) {
            const coverFile = this.fileList[0].originFileObj || this.fileList[0];
            if (coverFile instanceof File) {
              formData.append('coverPicture', coverFile);
            }
          }

          this.pictureFileList.forEach((file) => {
            const pictureFile = file.originFileObj || file;
            if (pictureFile instanceof File) {
              formData.append('pictures', pictureFile);
            }
          });

          if (this.removedPictures.length > 0) {
            this.removedPictures.forEach((pictureUrl) => {
              formData.append('removedPictures[]', pictureUrl);
            });
          }

          if (Array.isArray(values.tags)) {
            values.tags.forEach((tag) => formData.append('tags[]', tag));
          }

          try {
            await apiClient.put(`/marketplace/product/${this.productData._id}`, formData);
            this.$message.success(this.$t('success.productUpdate'));
            this.handleCancel();
            this.$emit('productUpdated');
          } catch (e) {
            console.error(e.response || e.message);
            this.$message.error(this.$t('error.productUpdate'));
          } finally {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    },
    beforeUploadContent(file, type) {
  const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
  if (!isValidType) {
    this.$message.error(this.$t('product.invalidImageType')); 
    return false;
  }

  file.uid = Date.now() + '-' + Math.random().toString(36).substr(2, 9);

  if (type === 'cover') {
    this.fileList = [{
      uid: file.uid,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
      thumbUrl: URL.createObjectURL(file),
      originFileObj: file,
    }];
  } else if (type === 'pictures') {
    this.pictureFileList = [...this.pictureFileList, {
      uid: file.uid,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
      thumbUrl: URL.createObjectURL(file),
      originFileObj: file,
    }];
  }
  return false; 
},

    handleRemoveCover() {
      this.removedCover = true;
      this.fileList = [];
    },
    handleRemovePicture(file) {
      this.pictureFileList = this.pictureFileList.filter(picture => picture.uid !== file.uid);
      
      if (!file.originFileObj) {
        this.removedPictures.push(file.url);
      }
    },
    handleCustomRequest({ file, onSuccess }) {
      setTimeout(() => {
        onSuccess('ok');
      }, 1000);
    },
    onEditorInput() {
      const editorContent = this.$refs.quillEditor.quill.root.innerHTML;
      this.description = editorContent;
    },
    stripHTML(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.innerText.trim();
    },
  },
};
</script>
