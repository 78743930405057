<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
       
        <a-tab-pane :tab="$t('product.Products')" key="1" />
        <a-tab-pane :tab="$t('command.Commands')" key="2" />
      </a-tabs>
    </div>
    <div class="card-body">
    
      <keep-alive>
        <component :is="currentTabComponent" :key="loadKey"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ProductList from './components/ProductList.vue';
import CommandList from './components/CommandList.vue';

export default {
  name: "MainComponent",
  components: {
    ProductList,
    CommandList,
  },
  data() {
    return {
      activeKey: "1", 
      loadKey: 0,     
    };
  },
  computed: {
    
    currentTabComponent() {
      switch (this.activeKey) {
        case "1":
          return "ProductList";
        case "2":
          return "CommandList";
        default:
          return "ProductList";
      }
    },
  },
  methods: {
    
    callback(key) {
      this.activeKey = key;
      this.loadKey += 1; 
    },
  },
};
</script>
