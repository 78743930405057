<template>
  <div>
    <a-spin :spinning="loading">
      <div class="row mb-3">
        <div class="col">
          <a-button icon="plus" @click="showModal" class="w-100">
            {{ $t("product.addProduct") }}
          </a-button>
        </div>
        <div class="col">
          <a-input :placeholder="$t('product.search')" @change="onSearch" class="w-100" />
        </div>
        <div class="col">
          <a-input-number :placeholder="$t('product.minprice')" v-model="minPrice" style="width: 100%" @change="onPriceFilterChange" />
        </div>
        <div class="col">
          <a-input-number :placeholder="$t('product.maxprice')" v-model="maxPrice" style="width: 100%" @change="onPriceFilterChange" />
        </div>
        <div class="col">
          <a-select
            v-model="selectedTags"
            :placeholder="$t('product.tags')"
            style="width: 100%"
            @change="onTagsFilterChange"
            mode="multiple" 
          >
            <a-select-option v-for="tag in uniqueTags" :key="tag" :value="tag">{{ tag }}</a-select-option>
          </a-select>
        </div>

        <div class="col">
          <a-switch
            default-checked
            :checked-children="$t('utilisateur.active')"
            :un-checked-children="$t('utilisateur.nonActive')"
            :checked="!showArchived"
            @change="toggleArchived"
          />
        </div>
      </div>

      <a-table
        :loading="tableLoading"
        :rowKey="'_id'"
        :columns="columns"
        :data-source="filteredData"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
        @row-click="(record, event) => onRowClick(record, event)"
      >
        <!-- Custom columns -->
        <template v-for="col in ['availability', 'price', 'quantity', 'updatedAt']" :slot="col" slot-scope="text, record">
          <div v-if="col === 'availability'" :key="'availability-' + record._id">
            <a-tag :color="getTagColor(record.availability)">
              {{ getTagText(record.availability) }}
            </a-tag>
          </div>
          <div v-else-if="col === 'price'" :key="'price-' + record._id">
            {{ text }} {{ $t("product.currency") }}
          </div>
          <div v-else-if="col === 'quantity'" :key="'quantity-' + record._id">
            {{ text }}
          </div>
          <div v-else-if="col === 'updatedAt'" :key="'updatedAt-' + record._id">
            {{ moment(text).format("DD/MM/YYYY HH:mm") }}
          </div>
        </template>
        <template slot="productImage" slot-scope="text, record">
          <img v-if="record.coverPicture" :src="record.coverPicture" style="width: 50px; height: 50px;" />
        </template>
        <template slot="tags" slot-scope="text, record">
          <div>
            <div v-for="tag in record.tags" :key="tag._id" style="margin-bottom: 4px;">
              <a-tag>{{ tag.name }}</a-tag>
            </div>
          </div>
        </template>

        <span slot="operation" slot-scope="text, record">
          <span class="button-spacing">
            <a-button @click="() => $router.push('/product/' + record._id)">
              <a-icon type="eye" />{{ $t("action.voir") }}
            </a-button>
          </span>

          <!-- Only show Edit button for active products -->
          <span v-if="!showArchived" class="button-spacing">
            <a-button type="primary" @click="editProduct(record)">
              <a-icon type="edit" />{{ $t("action.modifier") }}
            </a-button>
          </span>

          <!-- Show Annuler for active products, Reactivate for inactive products -->
          <span v-if="!showArchived" class="button-spacing">
            <a-popconfirm :title="$t('requis.supp')" @confirm="supp(record._id)">
              <a-button type="danger">
                <a-icon type="delete" />  
                <span style="margin-left: 8px;">{{ $t("action.annuler") }}</span>
              </a-button>
            </a-popconfirm>
          </span>

          <!-- Show Reactivate button for inactive products -->
          <span v-if="showArchived" class="button-spacing">
            <a-popconfirm :title="$t('all.sureTo')" @confirm="reactivateProduct(record._id)">
              <a-button type="primary">
                <a-icon type="plus" />  
                <span style="margin-left: 8px;">{{ $t("liste.reactive") }}</span>
              </a-button>
            </a-popconfirm>
          </span>
        </span>
      </a-table>

      <AddProduct :visible="showDrawer" @close="showDrawer = false" @submit="handleProductSubmit" :existingTags="uniqueTags" />
      <EditProduct :visible="showEditDrawer" :productData="currentProduct" :existingTags="uniqueTags" @close="showEditDrawer = false" @productUpdated="handleProductUpdated" />
    </a-spin>
  </div>
</template>
<script>
import AddProduct from './addProduct.vue';
import EditProduct from './EditProduct.vue';
import moment from "moment";
import apiClient from '@/services/axios';

export default {
  name: "ProductList",
  components: { AddProduct, EditProduct },
  data() {
    return {
      showDrawer: false,
      searchTerm: '',
      minPrice: null,
      maxPrice: null,
      selectedTags: [],
      showEditDrawer: false,
      currentProduct: null,
      data: [],
      loading: false, 
      tableLoading: false,
      showArchived: false,
    };
  },
  computed: {
    uniqueTags() {
      const tagsSet = new Set();
      this.data.forEach((product) => {
        if (product.tags) {
          product.tags.forEach((tag) => {
            if (tag.name && typeof tag.name === 'string') {
              tagsSet.add(tag.name);
            }
          });
        }
      });
      return [...tagsSet];
    },
    filteredData() {
      let filtered = this.data;

      filtered = filtered.filter(product => this.showArchived ? product.status === 'inactive' : product.status === 'active');

      if (this.searchTerm) {
        filtered = filtered.filter((item) =>
          item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }

      // Filter by price range
      if (this.minPrice !== null) {
        filtered = filtered.filter((item) => item.price >= this.minPrice);
      }
      if (this.maxPrice !== null) {
        filtered = filtered.filter((item) => item.price <= this.maxPrice);
      }

      // Filter by selected tags
      if (this.selectedTags.length > 0) {
        filtered = filtered.filter((item) =>
          this.selectedTags.some((tag) =>
            item.tags.some((productTag) => productTag.name === tag)
          )
        );
      }
      return filtered;
    },
    columns() {
      return [
        {
          title: this.$t("product.image"),
          dataIndex: "productImage",
          key: "productImage",
          scopedSlots: { customRender: "productImage" },
        },
        {
          title: this.$t("product.name"),
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.localeCompare(b.name),
         
          sortDirections: ["descend", "ascend"],
          
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style="padding: 8px;">
              <a-input
           placeholder="Enter name"
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style="width: 188px; margin-bottom: 8px; display: block;"
              />
              <a-button
                type="primary"
                onClick={() => confirm()}
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
              >
                 { this.$t('paiement.chercher') }
              </a-button>
              <a-button
                onClick={() => clearFilters()}
                size="small"
                style="width: 90px;"
              >
              { this.$t('action.reinitialiser') }
              </a-button>
            </div>
          ),
          onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
        },
      {
        title: this.$t("product.availability"),
        dataIndex: "availability",
        key: "availability",
        scopedSlots: { customRender: "availability" },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style="padding: 8px;">
            <a-checkbox-group
              value={selectedKeys}
              onChange={(checkedValues) => setSelectedKeys(checkedValues)}
              style="display: block;"
            >
              <a-checkbox value="available" style="display: block;">{ this.$t('product.available') }</a-checkbox>
              <a-checkbox value="soon" style="display: block;">{ this.$t('product.comingSoon') }</a-checkbox>
              <a-checkbox value="out" style="display: block;">{ this.$t('product.outOfStock') }</a-checkbox>
            </a-checkbox-group>
            <a-button
              type="primary"
              icon="search"
              onClick={() => confirm()}
              size="small"
              style="width: 90px; margin-top: 8px; margin-right: 8px;"
            >
            { this.$t('paiement.chercher') }
            </a-button>
            <a-button
              onClick={() => clearFilters()}
              size="small"
              style="width: 90px; margin-top: 8px;"
            >
            { this.$t('action.reinitialiser') }
            </a-button>
          </div>
        ),
        onFilter: (value, record) => value.includes(record.availability),
      },
        {
        title: this.$t("product.price"),
        dataIndex: "price",
        key: "price",
        sorter: (a, b) => a.price - b.price,
        sortDirections: ["descend", "ascend"],
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style="padding: 8px;">
            <a-input-number
              placeholder="Enter price"
              value={selectedKeys[0]}
              onChange={(value) => setSelectedKeys(value ? [value] : [])}
              style="width: 188px; margin-bottom: 8px; display: block;"
            />
            <a-button
              type="primary"
              icon="search"
              onClick={() => confirm()}
              size="small"
              style="width: 90px; margin-right: 8px;"
            >
            { this.$t('paiement.chercher') }
            </a-button>
            <a-button
              onClick={() => clearFilters()}
              size="small"
              style="width: 90px;"
            >
            { this.$t('action.reinitialiser') }
            </a-button>
          </div>
        ),
        onFilter: (value, record) => record.price == value,
      },
      {
        title: this.$t("product.quantity"),
        dataIndex: "quantity",
        key: "quantity",
        sorter: (a, b) => a.quantity - b.quantity,
        scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "quantity",
          },
        sortDirections: ["descend", "ascend"],
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style="padding: 8px;">
            <a-input-number
              placeholder="Enter quantity"
              value={selectedKeys[0]}
              onChange={(value) => setSelectedKeys(value ? [value] : [])}
              style="width: 188px; margin-bottom: 8px; display: block;"
            />
            <a-button
              type="primary"
              icon="search"
              onClick={() => confirm()}
              size="small"
              style="width: 90px; margin-right: 8px;"
            >
            { this.$t('paiement.chercher') }
            </a-button>
            <a-button
              onClick={() => clearFilters()}
              size="small"
              style="width: 90px;"
            >
            { this.$t('action.reinitialiser') }
            </a-button>
          </div>
        ),
        onFilter: (value, record) => record.quantity == value,
      },
      { 
        title: this.$t("command.createdAt"), 
        dataIndex: "createdAt", 
        key: "createdAt", 
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(), 
        customRender: (text) => {
          return text ? moment(text).format("DD/MM/YYYY HH:mm") : this.$t("product.noUpdate");
        }
      },
        {
  title: this.$t("product.lastUpdate"),
  dataIndex: "updatedAt",
  key: "updatedAt",
  sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
  sortDirections: ["descend", "ascend"],
  customRender: (text) => {
   
    return text ? moment(text).format("DD/MM/YYYY HH:mm") : this.$t("product.noUpdate");
  },
},
{
        title: this.$t("product.tags"),
        dataIndex: "tags",
        key: "tags",
        scopedSlots: { customRender: "tags" },
      },
        {
          title: this.$t("action.actions"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        
        },
      ];
    }
  },
  methods: {
    fetchProducts() {
      this.loading = true; 
      apiClient
        .post("/marketplace/product/filter", { query: {} })
        .then((response) => {
          this.data = response.data;
          this.filterProducts();
        })
        .finally(() => {
          this.loading = false; 
        })
        .catch((error) => {
          console.error("Failed to fetch products:", error);
         
        });
    },
    showModal() {
      this.showDrawer = true;
    },
    onSearch(event) {
      this.searchTerm = event.target.value;
    },
    onPriceFilterChange() {
  
  if (this.minPrice === '' || this.minPrice === null) {
    this.minPrice = null; 
  }
  if (this.maxPrice === '' || this.maxPrice === null) {
    this.maxPrice = null; 
  }
  
  this.filteredData; 
},
toggleArchived() {
      this.showArchived = !this.showArchived;
      this.filterProducts();
    },
    onTagsFilterChange(selectedTags) {
      this.selectedTags = selectedTags;
    },
    handleProductSubmit(productData) {
      this.data.push(productData);
      this.showDrawer = false;
      this.fetchProducts();
    },
    editProduct(product) {
      this.currentProduct = product;
      this.showEditDrawer = true;
      this.description = product.description || ''; 
    },
    handleProductUpdated() {
      this.fetchProducts();
      this.showEditDrawer = false;
    },
    getTagText(availability) {
      switch (availability) {
        case 'available':
          return this.$t("product.available");
        case 'soon':
          return this.$t("product.comingSoon");
        case 'out':
          return this.$t("product.outOfStock");
        default:
          return this.$t("product.unavailable");
      }
    },
    getTagColor(availability) {
      switch (availability) {
        case 'available':
          return 'green';
        case 'soon':
          return 'orange';
        case 'out':
          return 'red';
        default:
          return 'gray';
      }
    },
    reactivateProduct(productId) {
        
        apiClient.post(`/marketplace/product/activate`, { query: { _id: productId } })
            .then(() => {
                this.$message.success(this.$t("success.prodReactivate"));
                this.fetchProducts(); 
            })
            .catch(error => {
                console.error("Failed to reactivate product:", error);
                this.$message.error(this.$t("error.prodReactivate") + ' ' + (error.response.data.error || ''));
            });
    },
    supp(productId) {
      
        apiClient.post(`/marketplace/product/deactivate`, { query: { _id: productId } })
            .then(() => {
                this.$message.success(this.$t("success.prodSupp"));
                this.fetchProducts();  
            })
            .catch(error => {
                console.error("Failed to deactivate product:", error);
                this.$message.error(this.$t("error.autorisationSupp") + ' ' + (error.response.data.error || ''));
            });
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>
<style scoped>
.button-spacing {
  margin-right: 30px;
}

</style>